import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’re still hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
text-to-speech (TTS) and speech-to-text (STT) engines (over 750K downloads and 30K GitHub
stars), with the backing of top-flight investors `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "welcome-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcome-",
        "aria-label": "welcome  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Welcome! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Happy New Year! 🎉🎊🥳`}</p>
    <p>{`With this new year Coqui welcomes a new member to our team, Aya AlJa’fari🎉!
Aya is joining us as a Senior Deep Learning Engineer and we feel lucky to have
her on board. Please join us in welcoming Aya 🎉!`}</p>
    <p>{`Also we’ve added YourTTS to our TTS repo, a new model we’re pretty excited about.
With only a very small amount of data it’s able to clone your voice, which is
exciting enough, but it’s also able to clone your voice into any number of languages.
You give a small English sample, then out comes a cloned voice in Brazilian
Portuguese or any number of other languages! We’ve set up a `}<a parentName="p" {...{
        "href": "/index"
      }}>{`demo`}</a>{`.
Try it out!`}</p>
    <p>{`We’ve also released 🐸TTS v0.5.0, a new 🐸TTS version for you, delivered fresh!
With tons of new features:`}</p>
    <ul>
      <li parentName="ul">{`Multilingual training API`}</li>
      <li parentName="ul">{`YourTTS code and models`}</li>
      <li parentName="ul">{`Ukrainian Multi-band MelGAN`}</li>
    </ul>
    <p>{`Full of goodness!`}</p>
    <p>{`Last but certainly not least, is news from NeurIPS, the biggest deep learning
conference on the planet. Collaborating closely with Harvard, Google, and ML
Commons at NeurIPS we released the Multilingual Spoken Words Corpus! It has
340,000 keywords in 50 languages 🤯and we released it under a Creative Commons
BY-SA license! You can use it for research and production!`}</p>
    <p>{`Enjoy the new year🎉🎊🥳`}</p>
    <h3 {...{
      "id": "welcoming-aya-aljafari-to-the-coqui-team",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcoming-aya-aljafari-to-the-coqui-team",
        "aria-label": "welcoming aya aljafari to the coqui team permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Welcoming Aya AlJa’fari to the Coqui team`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.2%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFBv/EABYBAQEBAAAAAAAAAAAAAAAAAAUCA//aAAwDAQACEAMQAAAByM0C2ohZv//EABkQAQACAwAAAAAAAAAAAAAAAAEDBAIFE//aAAgBAQABBQKsVMYyTXLIHT//xAAYEQEAAwEAAAAAAAAAAAAAAAABAhASIf/aAAgBAwEBPwGAZOV//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABsQAAICAwEAAAAAAAAAAAAAAAECACEDERJB/9oACAEBAAY/AkLo5Ng69qHrDmPtHUbml3Qn/8QAGRABAQADAQAAAAAAAAAAAAAAAREAMVEh/9oACAEBAAE/IWXkFETtvuVqFiIPeXmXlMmmjP/aAAwDAQACAAMAAAAQdA//xAAXEQEBAQEAAAAAAAAAAAAAAAABADGR/9oACAEDAQE/EEMjhO3/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EEr21v/EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBMWH/2gAIAQEAAT8QKgLqQCr40VVQpSwXGnBrivBX5KSsATphP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/7730eb2f4125d539aa6c11e1d4aa8873/dbdff/welcome.jpg",
          "srcSet": ["/static/7730eb2f4125d539aa6c11e1d4aa8873/0988f/welcome.jpg 250w", "/static/7730eb2f4125d539aa6c11e1d4aa8873/d1f95/welcome.jpg 500w", "/static/7730eb2f4125d539aa6c11e1d4aa8873/dbdff/welcome.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`We are very excited to announce that Coqui is growing! We welcome Aya AlJa’fari,
joining us as Senior Deep Learning Engineer and employee #1 at Coqui! Aya joins
us from Jordan and brings extensive experience building production speech-to-text
systems. Aya has impressed us from our first calls, so it is very exciting to
have her on board. Those of you following the repository have already noticed
that Aya is already hard at work improving usability and accuracy for the
🐸STT engine!`}</p>
    <p>{`Aya has been working as a machine learning engineer for 3.5 years in the field of
speech recognition and natural language processing at Samsung Research and
Development Institute. She specializes in Electrical Engineering and Signal
Processing and has worked on the problem of speaker anonymization using GANs
in her master’s thesis. She has worked on several machine learning projects
as part of a team responsible for enhancing and expanding the language support
of Samsung’s speech recognition technology. She also developed a number of
proof-of-concepts and filed four patents in the field.`}</p>
    <p>{`We will continue to improve our 🐸STT packages, models and training infrastructure
to bring high quality speech-to-text to a variety of languages, use cases, and
technical stacks, from research to production. Does that sound interesting?
Join our `}<a parentName="p" {...{
        "href": "https://gitter.im/coqui-ai/STT"
      }}>{`Gitter chat`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/discussions"
      }}>{`discussion forums`}</a>{` to keep
up-to-date with the latest developments and to collaborate with Aya and the
rest of the team! Already an expert? `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`We’re hiring!`}</a></p>
    <h3 {...{
      "id": "-yourtts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-yourtts",
        "aria-label": " yourtts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👉🏽 YourTTS`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.4%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACCklEQVQoz22TW4/aMBCF+f//pH3uVvtSVKpWWxbYQhYKhc0NsiThlgQIiRPbp2OHRI1US1Zs+cznmeNJh3OOKIpQDyll6yuEoA2QJGcobXpNMRkbKFjR0tXrjlqUJcfW88Fy9l8RYzdkWar3UXqAYQ8RpydIISm21Bfpi2loICsZhq997KNdk5WaCqbYaZpQFScdOPcn6P1+xCqctS5VZw1wm7jozR8x276gILjKWN2sZgWuAlmRY+z20R1/xtQbEUSdE0zwNtAM5/i56MFYD5CxVAMUqAZqKAUWZYGFb6D/5xuWwewOFBrWAFVwyRhc18L1eruXKZsy6tJrcEaPYlsm8oxVOsh2yYI2RZ5h9TbHKYrBS954UwP/nen1AssxydcbaUWjVdU0JYfxFqPVE+xwhX0S4Ov0kWxY6HapQXUGzs7E2H6Gf9pgvbfwxXiAH3vttlnQyz2MPuJp1aV2iOAEbzgme2qjAoLfs+NVNkP7Oz4NP2CyfsbllsAJTYpJkOel9r6TsiveTy4c34S7U4dHHZgXGWUb0jfXmaoMz1mMzcGGG1jYHG2k+aUqN4uQnn16OI7OYmNgYg0oRsDylxgsf4DxXMPVy6sG1n/KNdG6paf6T2Lq/MKr8wIhBY7eAIHVJU8zKpnE8u6tag1xN/pGprvOujFc/0US1VTNz6XWq3E4xvDeA73+C7qZmFjehaIgAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/f793ed94f893c5696a57a6b1ac39a252/da8b6/yourtts.png",
          "srcSet": ["/static/f793ed94f893c5696a57a6b1ac39a252/43fa5/yourtts.png 250w", "/static/f793ed94f893c5696a57a6b1ac39a252/c6e3d/yourtts.png 500w", "/static/f793ed94f893c5696a57a6b1ac39a252/da8b6/yourtts.png 1000w", "/static/f793ed94f893c5696a57a6b1ac39a252/2e9ed/yourtts.png 1500w", "/static/f793ed94f893c5696a57a6b1ac39a252/39a40/yourtts.png 1874w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`The recent surge of new end-to-end deep learning models has enabled new
and exciting Text-to-Speech (TTS) use-cases with impressive natural-sounding
results. However, most of these models are trained on massive datasets (20-40 hours)
recorded with a single speaker in a professional environment. In this setting,
expanding your solution to multiple languages and speakers is not feasible for
everyone. Moreover, it is particularly tough for low-resource languages not
commonly targeted by mainstream research. To get rid of these limitations and
bring zero-shot TTS to low resource languages, we built YourTTS, which can
synthesize voices in multiple languages and reduce data requirements significantly
by transferring knowledge among languages in the training set. For instance,
we can easily introduce Brazilian Portuguese to the model with a single speaker
dataset by co-training with a larger English dataset. The model can then speak
Brazilian Portuguese with voices from the English dataset, or we can even
introduce new speakers by zero-shot learning on the fly.`}</p>
    <p>{`For more visit our `}<a parentName="p" {...{
        "href": "/blog/tts/yourtts-zero-shot-text-synthesis-low-resource-languages"
      }}>{`blog post`}</a></p>
    <ul>
      <li parentName="ul">{`👉 Try out YourTTS `}<a parentName="li" {...{
          "href": "/index"
        }}>{`demo`}</a>{` (Made with `}<a parentName="li" {...{
          "href": "https://gradio.app"
        }}>{`Gradio`}</a>{` 👑)`}</li>
      <li parentName="ul">{`👉 Visit YourTTS `}<a parentName="li" {...{
          "href": "https://edresson.github.io/YourTTS/"
        }}>{`project page`}</a></li>
      <li parentName="ul">{`👉 Try YourTTS on `}<a parentName="li" {...{
          "href": "https://colab.research.google.com/drive/1ftI0x16iqKgiQFgTjTDgRpOM1wC1U-yS?usp=sharing"
        }}>{`Colab`}</a></li>
      <li parentName="ul">{`👉 Try voice conversion with YourTTS on `}<a parentName="li" {...{
          "href": "https://colab.research.google.com/drive/1gjdwOKCZuavPn_5oy8QA01sKmXpEq5AZ?usp=sharing"
        }}>{`Colab`}</a></li>
    </ul>
    <p>{`Big thanks to all the 🐸TTS contributors who made this work possible and to all the authors;
`}<a parentName="p" {...{
        "href": "https://arxiv.org/search/cs?searchtype=author&query=Casanova%2C+E"
      }}>{`Edresson Casanova`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://arxiv.org/search/cs?searchtype=author&query=Weber%2C+J"
      }}>{`Julian Weber`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://arxiv.org/search/cs?searchtype=author&query=Shulby%2C+C"
      }}>{`Christopher Shulby`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://arxiv.org/search/cs?searchtype=author&query=Junior%2C+A+C"
      }}>{`Arnaldo Candido Junior`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://arxiv.org/search/cs?searchtype=author&query=Ponti%2C+M+A"
      }}>{`Moacir Antonelli Ponti`}</a></p>
    <h3 {...{
      "id": "-tts-v050",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-tts-v050",
        "aria-label": " tts v050 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸💬 TTS v0.5.0`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`v0.5.0 comes with a long list of updates. Some of the important ones are:`}</p>
    <ul>
      <li parentName="ul">{`Multilingual training API`}</li>
      <li parentName="ul">{`YourTTS model implementation`}</li>
      <li parentName="ul">{`YourTTS model release.`}</li>
    </ul>
    <p>{`For example, using YourTTS is as easy as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`tts --text "this is my sample text." \\
    --model_name tts_models/multilingual/multi-dataset/your_tts \\
    --speaker_wav reference/voice/path.wav --language_idx en
`}</code></pre>
    <p>{`Also V0.5.0 comes with`}</p>
    <ul>
      <li parentName="ul">{`Ukrainian Multi-band MelGAN vocoder release. (👑@robinhad)`}</li>
    </ul>
    <p>{`which is callable as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`tts --text "як ти сьогодні?" --model_name tts_models/uk/mai/glow-tts
`}</code></pre>
    <p>{`Check the `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.5.0"
      }}>{`release notes`}</a>{` for more details.`}</p>
    <h3 {...{
      "id": "neurips-data-set--data-panel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#neurips-data-set--data-panel",
        "aria-label": "neurips data set  data panel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`NeurIPS data set + data panel`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABT0lEQVQY01WQWU/CUBCF+f8/wwfjgzFu+KQmRiTSRY19ACptaUs0VgUK3ej2ObchJt7cyZxZz+T0NpuY5Tphm+woipK6rmmamrZtgPa/ta389q+W7XYEnxHfX1/EcSxzDb3V8ofnacjA8jDGC6zZO2M3IvxYE30nrONcyAqyvBTCiqpsqKtmv78hzjakyZYkSbpjeolsvn21OBjccTgYcvgw5OjhkZORwfHQ5EJ7oa9Z3LxMuH6acG/NhNzhcexiTAO8jyWxEJZC0l2Ybrfo9hsXhk7fNMX0Dl89GZzpGn3xl6YyjVNtJF7qus65IfFI6obJ/auNPnZIs4KeiEEURTizGa7r4vs+c8/Dn89ZhAvs6RRP8sE+r/oWYYgn2HUcgsDHcR3sN5u82C9Up2ZZ1umg8E7EVrHSROXSNO1wWZZdXFUVeZ53fQqvVquuX71fu1+59tSeKo0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/7ec188efe631c7b17702099021562a50/da8b6/mswc.png",
          "srcSet": ["/static/7ec188efe631c7b17702099021562a50/43fa5/mswc.png 250w", "/static/7ec188efe631c7b17702099021562a50/c6e3d/mswc.png 500w", "/static/7ec188efe631c7b17702099021562a50/da8b6/mswc.png 1000w", "/static/7ec188efe631c7b17702099021562a50/2e9ed/mswc.png 1500w", "/static/7ec188efe631c7b17702099021562a50/9fabd/mswc.png 2000w", "/static/7ec188efe631c7b17702099021562a50/e7591/mswc.png 2032w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Coqui has been collaborating closely with researchers from Harvard, Google, and
ML Commons to create and deliver a first of its kind voice dataset: the
Multilingual Spoken Words Corpus.`}</p>
    <p>{`The data set is perfect for training wakeword, hotword, or command-and-control
speech applications. It’s got a whopping 340,000 keywords in 50 languages
🤯Best part? We released it under a Creative Commons BY-SA license, so the
data’s yours for both research and production.`}</p>
    <p>{`We presented the dataset at NeurIPS 2021, in `}<a parentName="p" {...{
        "href": "https://blog.neurips.cc/2021/11/19/announcing-the-neurips-2021-datasets-and-benchmarks-track-papers/"
      }}>{`the inaugural Datasets and
Benchmarks track`}</a>{`.
NeurIPS created this special new track “to serve as a venue for exceptional
work focused on creating high-quality datasets.”`}</p>
    <p>{`You can watch `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=eGPCwnRtRng"
      }}>{`the official NeurIPS video presentation`}</a>{`
for yourself.`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      